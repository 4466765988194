<template>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="500px"
        v-loading="loading"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <span>确定筛选通过，并将简历立即推荐给HR吗？</span>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelHandle">取消</el-button>
            <el-button type="primary" @click="confirmHandle">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import myService from '#/js/service/myService.js';
    export default {
        name: "reject-dialog.vue",
        components: {
        },
        data(){
            return {
                dialogVisible:false,
                loading:false,
                interviewid:'',
                recommendationId:''
            }
        },
        methods: {
            /**
             * 调用dialog
             * @param recommendationId 候选人推荐id 必须包含以下信息：id
             */
            showHandle(recommendationId) {
                this.recommendationId = recommendationId;
                this.dialogVisible = true;
            },
            /**
             * 确认按钮fn
             */
            confirmHandle(){
                this.loading = true;
                myService
                    .approve(this.recommendationId, this.$store.state.verificationToken.verificationToken)
                    .then((res) => {
                        this.$emit('successFn', res);
                        this.dialogVisible = false;
                    }).finally(() => {
                        this.loading = false;
                    });
            },
            cancelHandle() {
                this.dialogVisible = false;
            }
        },
        watch: {
            reason (value){
                console.log(value);
            }
        }
    }
</script>


<style lang="scss" scoped>
    .el-row {
        line-height: 40px;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
</style>
