<template>
    <el-dialog
        title="动态"
        :visible.sync="dialogVisible"
        width="600px"
        :before-close="cancelHandle"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <div class="pingan-job-status-dialog">
            <el-container v-for="(item, index) in recommendList" v-if="index < lock && recommendList.length > 0">
                <el-aside width="30px">
                    <div class="point">

                    </div>
                    <div class="line">

                    </div>
                </el-aside>
                <el-main>
                    <el-row>
                        <span class="time"> {{ item.operationTime }}</span>
                    </el-row>

                    <el-row class="info">
                        <span>{{ item.contentDync }}</span>
                    </el-row>
                </el-main>
            </el-container>

            <div class="default" v-if="recommendList.length <= 0">
                <img src="@src/assets/images/member/pic_noworker.png" alt="">
                <span>暂无数据</span>
            </div>
        </div>

        <el-button
            class="btn-more-info"
            type="default"
            @click="moreList"
            v-if="lock < recommendList.length"
            >
            加载更多
        </el-button>

        <div
            class="no-more"
            v-if="lock >= recommendList.length"
            >
            没有更多信息
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelHandle">取消</el-button>
            <el-button type="primary" @click="confirmHandle">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import pinganService from '#/js/service/pinganService';
    export default {
        name: "pingan-job-status-dialog",
        components: {
        },
        data() {
            return {
                dialogVisible:false,
                recommendList: [],
                candidateId: '',
                lock: 10,
            }
        },
        methods: {
            init (){
                if(!this.candidateId) {
                    return false;
                }
                return pinganService
                    .getRecommendationStatusList(this.candidateId)
                    .then(res => {
                        this.recommendList = res;
                    })
            },
            showHandle(id) {
                this.candidateId = id;
                this.init()
                    .then(() => {
                        this.dialogVisible = true;
                    })

            },
            confirmHandle (){
                this.dialogVisible = false;
                return console.log('confirm!')
            },
            cancelHandle (){
                this.dialogVisible = false;
                return console.log('cancel!')
            },
            moreList() {
                if(this.lock < this.recommendList.length){
                    this.lock = this.lock + 10;
                } else {
                    return this.$message.error('已经没有更多的动态！');
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .pingan-job-status-dialog{
        max-height: 328px;
        overflow-y: scroll;

        .el-container{
            color: #000;
            &:last-child .el-main{
                padding: 0;
            }

            &:first-child .line{
                top: 6px;
            }
        }
    }

    .el-aside{
        position: relative;
        overflow: hidden;
    }

    .el-main{
        padding: 0 0 24px 0;
    }

    .point{
        margin: auto;
        margin-top: 6px;
        width: 10px;
        height: 10px;
        background-color: $primary;
        border-radius: 50%;
    }

    .line{
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: 2px;
        height: 100%;
        opacity: 0.2;
        border-radius: 2px;
        background-color: $primary;
    }

    .time{
        font-size: 12px;
        color: #999999;
        letter-spacing: 0;
    }

    .info{
        margin-top: 4px;
        color: #666;
        line-height: 19px;
    }

    .btn-more-info{
        margin-top: 24px;
        width: 100%;
        border: 1px solid $primary;
        color: $primary;
        border-radius: 2px;
    }

    .default{
        display: table;
        margin: auto;
    }

    .no-more{
        padding-top: 20px;
        text-align: center;
        color: #999;
    }
</style>
