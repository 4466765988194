var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "add-customer-status" },
    [
      _vm.isRecommendToCustomer && _vm.activeRecommendationStatus === 0
        ? _c("p", { staticClass: "success recommend-success" }, [
            _vm._m(0),
            _c(
              "span",
              {
                staticClass: "operate-btn",
                on: {
                  click: function ($event) {
                    return _vm.getStatus(1)
                  },
                },
              },
              [_vm._v("查看推荐情况")]
            ),
          ])
        : _vm._e(),
      _vm.activeRecommendationStatus === 3
        ? _c("p", { staticClass: "success" }, [
            _c("span", { staticClass: "text" }, [
              _c("i"),
              _vm._v(
                "成功推荐给客户，当前候选人状态：[ " +
                  _vm._s(_vm.candidateStatusMap[_vm.activeCandidateStatus]) +
                  " ]"
              ),
            ]),
            _c(
              "span",
              {
                staticClass: "operate-btn",
                on: {
                  click: function ($event) {
                    return _vm.getStatus(2)
                  },
                },
              },
              [_vm._v("刷新")]
            ),
            _c(
              "span",
              {
                staticClass: "operate-btn",
                on: { click: _vm.showStatusListDialog },
              },
              [_vm._v("更多状态")]
            ),
          ])
        : _vm._e(),
      _vm.activeRecommendationStatus === 2
        ? _c("p", { staticClass: "fail" }, [
            _vm._m(1),
            _c(
              "span",
              { staticClass: "operate-btn", on: { click: _vm.retryRecommend } },
              [_vm._v("再次推荐")]
            ),
          ])
        : _vm._e(),
      _vm.activeRecommendationStatus === 1
        ? _c("p", { staticClass: "warn" }, [_vm._m(2)])
        : _vm._e(),
      _c("pinganJobStatusDialog", { ref: "pinganJobStatusDialog" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "text" }, [
      _c("i"),
      _vm._v("已推荐给客户"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "text" }, [_c("i"), _vm._v("推荐失败")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "text" }, [_c("i"), _vm._v("候选人查重")])
  },
]
render._withStripped = true

export { render, staticRenderFns }