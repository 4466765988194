<template>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="500px"
        v-loading="loading"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <span>确定要取消面试吗？取消后将邮件、短信通知HR和候选人</span>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelHandle">取消</el-button>
            <el-button type="primary" @click="confirmHandle">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import myService from '#/js/service/myService.js';
    export default {
        name: "cancel-dialog.vue",
        components: {
        },
        data(){
            return {
                dialogVisible:false,
                loading:false,
                interviewid:'',
                recommendationId:''
            }
        },
        computed: {
            unionId() {
                return this.$store.state.user.userInfo.unionId;
            },
        },
        methods: {
            /**
             * 调用dialog
             * @param recommendationId 候选人推荐id
             * @param interviewid 面试id
             */
            showHandle(recommendationId,interviewid) {
                this.recommendationId = recommendationId;
                this.interviewid = interviewid;
                this.dialogVisible = true;
            },
            /**
             * 确认按钮fn
             */
            confirmHandle(){
                this.loading = true;
                // myService
                //     .cancel(this.recommendationId,this.interviewid, this.$store.state.verificationToken.verificationToken)
                //     .then((res) => {
                //         this.$emit('successFn', res);
                //         this.dialogVisible = false;
                //     }).finally(() => {
                //         this.loading = false;
                let params = {
                    interviewId: this.interviewid,
                    unionId: this.unionId,
                };
                myService
                    .cancelInterview(params)
                    .then((res) => {
                        shortTips('操作成功');
                        this.$emit('successFn', res);
                        this.dialogVisible = false;
                    }).finally(() => {
                        this.loading = false;
                    });
            },
            cancelHandle() {
                this.dialogVisible = false;
            }
        },
        watch: {
            reason (value){
                console.log(value);
            }
        }
    }
</script>


<style lang="scss" scoped>
    .el-row {
        line-height: 40px;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
</style>
