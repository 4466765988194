<template>
    <div class="add-customer-status">
        <p class="success recommend-success" v-if="isRecommendToCustomer && activeRecommendationStatus === 0">
            <span class="text"><i></i>已推荐给客户</span>
            <span class="operate-btn" @click="getStatus(1)">查看推荐情况</span>
        </p>

        <p class="success" v-if="activeRecommendationStatus === 3">
            <span class="text"><i></i>成功推荐给客户，当前候选人状态：[ {{candidateStatusMap[activeCandidateStatus]}} ]</span>
            <span class="operate-btn" @click="getStatus(2)">刷新</span>
            <span class="operate-btn" @click="showStatusListDialog">更多状态</span>
        </p>

        <p class="fail" v-if="activeRecommendationStatus === 2">
            <span class="text"><i></i>推荐失败</span>
            <span class="operate-btn" @click="retryRecommend">再次推荐</span>
        </p>

        <p class="warn" v-if="activeRecommendationStatus === 1">
            <span class="text"><i></i>候选人查重</span>
        </p>

        <pinganJobStatusDialog ref="pinganJobStatusDialog"></pinganJobStatusDialog>
    </div>
</template>

<script>
    import pinganService from '#/js/service/pinganService';
    import pinganJobStatusDialog from '#/component/common/dialog/pingan-job-status-dialog.vue';
    export default {
        name: "add-customer-status",
        components: {
            pinganJobStatusDialog
        },
        data() {
            return {
                localModelActive: false,
                localRecommendationStatusModel: {
                    recommendationStatus: 0,
                    candidateStatus: 0
                },
                candidateStatusMap: {
                    0: '未定义',
                    1: '筛选中',
                    2: '面试中',
                    3: '测评中',
                    4: 'offer',
                    5: '已淘汰',
                    6: '已入职',
                }
            }
        },
        props: {
            recommendId: String,
            isRecommendToCustomer: Boolean,
            candidateStatus: Number,
            recommendationStatus: Number,  //0:推荐中, 1:查重, 2:失败, 3:成功
        },
        computed: {
            activeRecommendationStatus() {
                if(this.localModelActive) {
                    return this.localRecommendationStatusModel.recommendationStatus;
                }else {
                    return this.recommendationStatus;
                }
            },
            activeCandidateStatus() {
                if(this.localModelActive) {
                    return this.localRecommendationStatusModel.candidateStatus;
                }else {
                    return this.candidateStatus;
                }
            }
        },
        methods: {
            getStatus(stage) {
                let eventName = stage === 1 ? 'viewTo_recommend_status' : 'refresh_recommend_status';
                lbdTrack.track(eventName,{
                    recommendId: this.recommendId
                });
                pinganService
                    .getRecommendationStatus(this.recommendId)
                    .then(res => {
                        if(this.localModelActive) {
                            shortTips('刷新成功');
                        }
                        this.updateLocalModel(res);
                    })
            },
            retryRecommend() {
                lbdTrack.track('retry_recommend',{
                    recommendId: this.recommendId
                });
                this.$emit('retry-recommend');
            },
            updateLocalModel(data) {
                this.localModelActive = true;
                this.localRecommendationStatusModel = data;
            },
            showStatusListDialog(){
                lbdTrack.track('show_status_dialog',{
                    recommendId: this.recommendId
                });
                this.$refs.pinganJobStatusDialog.showHandle(this.recommendId);
            }
        },
    }
</script>

<style lang="scss" scoped>
    .add-customer-status {
        font-size: 14px;
        color: #38bc9d;

        .text {
            margin-right: 30px;

            i {
                position: relative;
                display: inline-block;
                margin-left: 3px;
                margin-right: 13px;
                width: 8px;
                height: 8px;
                background-color: #38bc9d;
                border-radius: 50%;

                &::before {
                    content: '';
                    position: absolute;
                    top: -3px;
                    left: -3px;
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    background-color: #38bc9d;
                    opacity: 0.5;
                }
            }
        }

        .success {
            .text {
                color: #38bc9d;
            }
        }
        .recommend-success {
            .text {
                color: #4A90E2;
                i {
                    background-color: #4A90E2;

                    &::before {
                        background-color: #4A90E2;
                    }
                }
            }
        }
        .fail {
            .text {
                color: #fc7859;
                i {
                    background-color: #fc7859;

                    &::before {
                        background-color: #fc7859;
                    }
                }
            }
        }
        .warn {
            .text {
                color: #f98f33;
                i {
                    background-color: #f98f33;

                    &::before {
                        background-color: #f98f33;
                    }
                }
            }
        }

        .operate-btn {
            margin-right: 10px;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }
</style>
