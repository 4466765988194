var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-button",
    {
      staticClass: "add-customer btn btn-info",
      attrs: { type: "info", size: "sm", loading: _vm.isLoading },
      on: { click: _vm.recommend },
    },
    [_vm._v("推荐给客户")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }