<template>
    <el-button class="add-customer btn btn-info" type="info" size="sm" :loading="isLoading" @click="recommend">推荐给客户</el-button>
</template>

<script>
    import pinganService from '#/js/service/pinganService';

    export default {
        name: "add-customer",
        components: {
        },
        data() {
            return {
                isLoading: false
            }
        },
        props: {
            recommendId: String
        },
        created() {
        },
        computed: {
        },
        methods: {
            recommend() {
                let self = this;
                this.isLoading = true;
                pinganService
                    .recommendCustomer(this.recommendId)
                    .then(res => {
                        self.isLoading = false;
                        if(res) {
                            if(res.recommendationStatus === 3) {
                                shortTips('推荐成功');
                            }else if(res.recommendationStatus === 2) {
                                shortTips('推荐失败，请再次推荐');
                            }else if(res.recommendationStatus === 1) {
                                shortTips('候选人查重，不能推荐给客户');
                            }
                            this.$emit('update-recommend-status', res.recommendationStatus, res.candidateStatus);
                            if(res.recommendationUrl) {
                                window.open(res.recommendationUrl);
                            }
                        }
                    }).catch(err => {
                        self.isLoading = false;
                        console.log(err);
                    })
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
